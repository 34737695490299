import React from 'react'
import { Link, FormattedMessage } from 'gatsby-plugin-intl'
import LanguageSwitcher from './languageSwitcher'

const NavItems = () => {
  return (
    <nav>
      <ul className='nav'>
        <li>
          <Link to='/'>
            <FormattedMessage id='navigation.homePageLink' />
          </Link>
        </li>
        <li>
          <Link to='/io-scaling/'>
            <FormattedMessage id='navigation.ioScalingLink' />
          </Link>
        </li>
        <li>
          <Link to='/hostname-ip/'>
            <FormattedMessage id='navigation.hostnameIp' />
          </Link>
        </li>
        <li>
          <Link to='/remote-support/'>
            <FormattedMessage id='navigation.remoteSupport' />
          </Link>
        </li>
        <li>
          <LanguageSwitcher />
        </li>
      </ul>
    </nav>
  )
}

export default NavItems

import React from 'react'

import NavItems from './nav'

import logo from '../assets/images/v-icon.svg'
import onOff from '../assets/images/power-icon.svg'

const Header = ({ siteTitle }) => (
  <header>
    <div className='logo'>
      <img className='v-icon' src={logo} alt='Vitapoint Icon' />
    </div>
    <NavItems />
    <div className='switch'>
      <img src={onOff} alt='Power Icon' />
    </div>
  </header>
)

export default Header
